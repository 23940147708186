.PlanSpecificationChart .heading-table-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  border: none;
  color: #44403c;
}
.PlanSpecificationChart .heading-table-content .talk-to-sales {
  max-width: 160px;
}
.PlanSpecificationChart table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.PlanSpecificationChart th, .PlanSpecificationChart .tick {
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
}
.PlanSpecificationChart th, .PlanSpecificationChart td {
  text-align: center;
  border-top: 1px solid #d6d3d1;
  padding: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #44403c;
  letter-spacing: -0.02em;
  width: 18%;
}
.PlanSpecificationChart th.fs-r {
  max-width: 28%;
}
.PlanSpecificationChart th:first-child, .PlanSpecificationChart td:first-child {
  text-align: left;
}
.PlanSpecificationChart .heading-row {
  background: #f5f5f5;
  color: #44403c;
  height: 50px;
}
.PlanSpecificationChart .heading-row td {
  font-size: 18px;
  font-weight: bold;
  align-items: center;
}
.PlanSpecificationChart .heading-row td img {
  padding-right: 5px;
}
.PlanSpecificationChart .heading-row td svg {
  padding-right: 5px;
}
.PlanSpecificationChart .button-style {
  font-size: 14px !important;
}
.PlanSpecificationChart .opacityZero {
  opacity: 0;
}
@media screen and (max-width: 450px) {
  .PlanSpecificationChart .price-heading {
    font-size: 22px !important;
 }
  .PlanSpecificationChart th, .PlanSpecificationChart td {
    font-size: 12px !important;
 }
  .PlanSpecificationChart .plan-name {
    font-size: 11px !important;
 }
}
@media screen and (max-width: 800px) {
  .PlanSpecificationChart .price-heading {
    font-size: 28px !important;
 }
  .PlanSpecificationChart .erp-svg div svg {
    width: 100%;
 }
  .PlanSpecificationChart th, .PlanSpecificationChart td {
    padding: 5px;
 }
  .PlanSpecificationChart th div svg {
    height: 35px;
 }
}
.PlanSpecificationChart .price-heading {
  font-size: 44px;
}
@media screen and (max-width: 450px) {
  .pricing-tabs-icon {
    height: 30px;
    width: 30px;
 }
  .pricing-tabs-text {
    font-size: 32px !important;
    margin-left: 10px !important;
 }
  .pricingTabDiv {
    column-gap: 0 !important;
 }
}
@media screen and (min-width: 451px) and (max-width: 800px) {
  .pricing-tabs-icon {
    height: 40px;
    width: 40px;
 }
  .pricing-tabs-text {
    font-size: 42px !important;
    margin-left: 15px !important;
 }
  .pricingTabDiv {
    column-gap: 0 !important;
 }
}
.pricingCardContent {
  font-weight: 700;
  font-size: 12px;
  min-height: 240px;
  line-height: 20px;
  padding: 5px;
  width: 100%;
}
.pricingCardContentMRP {
  min-height: 200px;
}
.pricingCardMRPIndia {
  min-height: 275px !important;
}
.erp-blank-heading th:first-child {
  width: 29.5% !important;
}
.mrp-blank-heading th:first-child {
  width: 34% !important;
}
.mrp-blank-heading th {
  width: 21%;
}
.pricing-table-header-sticky {
  max-width: 1000px;
  width: 100%;
  display: flex;
  position: fixed;
}
.prod-heading {
  height: 52px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 20px;
  font-size: 24px;
  margin-right: 20px;
}
@media screen and (min-width: 801px) and (max-width: 1245px) {
  .pricing-table-header-sticky {
    top: 58px !important;
 }
}
@media screen and (max-width: 550px) {
  .prod-heading {
    height: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 20px;
    font-size: 14px;
 }
  .mrp-blank-heading th:first-child {
    width: 40% !important;
 }
}
.india-pricing-mrp .mrp-blank-heading th:first-child {
  width: 55% !important;
}
.india-pricing-mrp th.fs-r {
  max-width: none;
  width: 58% !important;
}
.india-pricing-mrp td {
  width: 21%;
}
.india-pricing-erp th.fs-r {
  max-width: 30%;
}

/* CardPayment.tsx */
.card-delete-icon:hover {
  opacity: 0.4;
}